define("ccc/templates/index", ["exports"], function (exports) {
  exports["default"] = Ember.HTMLBars.template((function () {
    return {
      meta: {
        "revision": "Ember@2.8.0",
        "loc": {
          "source": null,
          "start": {
            "line": 1,
            "column": 0
          },
          "end": {
            "line": 17,
            "column": 4
          }
        },
        "moduleName": "ccc/templates/index.hbs"
      },
      isEmpty: false,
      arity: 0,
      cachedFragment: null,
      hasRendered: false,
      buildFragment: function buildFragment(dom) {
        var el0 = dom.createDocumentFragment();
        var el1 = dom.createElement("div");
        dom.setAttribute(el1, "style", "height:300px; background-image: url('/assets/images/cabin_1.jpg'); background-repeat: no-repeat; background-size: cover; background-position: center;");
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("h1");
        var el2 = dom.createTextNode("About Cheryl");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("\nMy name is Cheryl Grace and I have a City  &  Guilds Level 3 qualification in dog grooming, having trained with top groomer Amy Manser, winner of numerous top grooming awards and competitions including Dog Groomer Of The Year. I have also  completed a canine first responder first aid training course and I attend seminars to further my knowledge as a dog groomer. Cheryl's Canine Cabin is fully insured.\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("\nI work from home in a specially built dog grooming cabin, fully insulated to keep lovely and warm in the winter but cool in the summer and fully panelled inside to keep it clean and fresh. There is also a secure garden for your pet  to have a break and stretch their legs. \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("\nI take great pride in my work and treat all my furry customers as if they were my own, you can be rest assured your pet will be treated with kindness and affection throughout the grooming process. Your dog will have my full attention on a  one to one, no other dogs are waiting to be groomed or awaiting collection, my aim is to provide your dog with a relaxing, safe and stress free experience. \n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        var el1 = dom.createTextNode("\n\n");
        dom.appendChild(el0, el1);
        var el1 = dom.createElement("p");
        var el2 = dom.createTextNode("\nAll Breeds and cross breeds welcome, prices depend on size, coat type and condition. Every dog is different and an individual so please get in touch for a chat to discuss your dog and its needs and if you have any queries I would be more than happy to answer them.\n");
        dom.appendChild(el1, el2);
        dom.appendChild(el0, el1);
        return el0;
      },
      buildRenderNodes: function buildRenderNodes() {
        return [];
      },
      statements: [],
      locals: [],
      templates: []
    };
  })());
});