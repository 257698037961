define('ccc/router', ['exports', 'ember', 'ccc/config/environment'], function (exports, _ember, _cccConfigEnvironment) {

  var Router = _ember['default'].Router.extend({
    location: _cccConfigEnvironment['default'].locationType,
    rootURL: _cccConfigEnvironment['default'].rootURL
  });

  Router.map(function () {
    this.route('index', { path: '/' });
    this.route('services');
    this.route('contact');
    this.route("fourZeroFour", { path: "*path" });
  });

  exports['default'] = Router;
});